<template>
  <div class="cost">
    <el-timeline>
      <template v-for="(item, index) in projectDeptBelongList">
        <el-timeline-item
          :key="index"
          :icon="item.type == 'THEIR' ? 'el-icon-more' : 'el-icon-check'"
          :type="item.type == 'THEIR' ? 'primary' : 'success'"
        >
          <slot name="timestamp">
            <div class="timestamp-title">
              <span>
                {{ item.deptId | cascader(deptData, 'id', 'deptName') }}
              </span>
              <el-button type="primary" @click="changeMember(item.type)"
                >项目成员变更记录</el-button
              >
            </div>
          </slot>
          <el-card>
            <table class="table">
              <tr>
                <th style="width: 50px">序号</th>
                <th style="width: 110px">姓名</th>
                <th style="width: 120px">手机号码</th>
                <th>岗位</th>
                <th>工作职责</th>
                <th style="width: 110px">加入项目时间</th>
                <th style="width: 150px">项目角色</th>
                <th style="width: 100px">在职状态</th>
                <th style="width: 80px" v-if="!jurisdiction(item)">
                  <ChoiceData
                    ref="ChoiceDataMember"
                    :selectedData="memberData[item.deptId]"
                    :configure="{
                      request: request,
                      params: {
                        deptId: item.deptId,
                      },
                    }"
                    @choiceDataChange="choiceDataChange($event, item.deptId)"
                    @updateDay="updateDay"
                  >
                    <i slot="button" class="iconfont iconadd-circle"></i>
                    <div slot="choiceItem" slot-scope="{ data }">
                      {{ data.userName }}
                    </div>
                    <div
                      class="mainList_operation clearfloat"
                      slot="search"
                      slot-scope="{ params, search }"
                    >
                      <div class="mainList_operation_search" id="member_search">
                        <span>
                          <!-- <el-select
                          style="width:125px;"
                          class="item"
                          v-model="params.deptId"
                          placeholder="请选择部门"
                          clearable
                        >
                          <el-option
                            v-for="item in deptData"
                            :label="item.deptName"
                            :value="item.id"
                          ></el-option>
                        </el-select> -->
                          <!-- <el-input
                            style="width: 115px"
                            class="item"
                            placeholder="用户名"
                            clearable
                            v-model="params.loginName"
                          ></el-input> -->
                          姓名:
                          <el-input
                            style="width: 200px"
                            class="item"
                            placeholder="请输入姓名"
                            clearable
                            v-model="params.userName"
                          ></el-input>
                          <el-button type="primary" plain icon="el-icon-search" @click="search(1)"
                            >搜索</el-button
                          >
                        </span>
                        <span style="margin-right: 10px">
                          设置统一加入项目时间：
                          <el-date-picker
                            style="margin-left: 6px; width: 184px"
                            v-model="joinDate"
                            type="date"
                            placeholder="选择日期"
                            @change="changeFn"
                            :clearable="false"
                            format="yyyy 年 MM 月 dd 日"
                            value-format="timestamp"
                          >
                          </el-date-picker>
                        </span>
                      </div>
                    </div>
                    <template slot="tableColumn">
                      <el-table-column
                        align="center"
                        prop="userName"
                        label="姓名"
                      ></el-table-column>
                      <el-table-column
                        align="center"
                        prop="phone"
                        label="手机号码"
                      ></el-table-column>
                      <el-table-column align="center" prop="joinDate" label="加入项目时间">
                        <template slot-scope="scope">
                          <span @click.stop.prevent>
                            <el-date-picker
                              v-model="scope.row.joinDate"
                              :disabled="scope.row.isCheckBox"
                              type="date"
                              :clearable="false"
                              @change="changeJoinDate(scope.row)"
                              placeholder="选择日期"
                              format="yyyy 年 MM 月 dd 日"
                              value-format="timestamp"
                            >
                            </el-date-picker>
                          </span>
                        </template>
                      </el-table-column>
                      <el-table-column align="center" prop="fileName" label="岗位">
                        <template slot-scope="scope">
                          {{ scope.row.postId | dict(postData, 'id', 'postName') }}
                        </template>
                      </el-table-column>
                    </template>
                  </ChoiceData>
                </th>
              </tr>
              <template v-for="(n, i) in memberData[item.deptId]">
                <tr :key="i">
                  <td>{{ i + 1 }}</td>
                  <td>{{ n.userName }}</td>
                  <td>{{ n.phone }}</td>
                  <td>{{ n.postId | dict(postData, 'id', 'postName') }}</td>
                  <td>
                    <!-- {{ n.joinResponsibility }} -->
                    <el-input
                      v-model="n.joinResponsibility"
                      type="textarea"
                      autosize
                      :disabled="jurisdiction(item)"
                      @input="$forceUpdate()"
                    ></el-input>
                  </td>
                  <td>{{ n.joinDate | dateFormat }}</td>
                  <td>{{ n.projectRole | dict(dictData.projectRole) }}</td>
                  <td>
                    <div
                      :style="
                        n.status == 1
                          ? 'line-height:30px;  text-align: center;'
                          : ' background-color: #bab4ad;line-height:30px;  text-align: center;color:#fff'
                      "
                    >
                      {{ n.status == 1 ? '在职' : '离职' }}
                    </div>
                  </td>
                  <td v-if="!jurisdiction(item)">
                    <i
                      v-if="n.projectRole != 'MANAGER' && n.projectRole != 'LEADER'"
                      class="iconfont iconerror"
                      @click="del(item.deptId, i, n)"
                    ></i>
                  </td>
                </tr>
              </template>
            </table>
          </el-card> </el-timeline-item
      ></template>
    </el-timeline>
    <el-dialog
      title="撤离成员"
      width="30%"
      center
      :visible.sync="dialogFormVisible"
      :before-close="removeFn"
      :append-to-body="true"
    >
      <el-form :model="form" ref="formDialog" :rules="rules">
        <el-form-item label="成员名称">
          <el-input disabled v-model="form.name"></el-input>
        </el-form-item>
        <el-form-item label="撤离时间" prop="leaveDate">
          <el-date-picker
            v-model="form.leaveDate"
            @change="leavDateFn"
            type="date"
            placeholder="选择日期"
            format="yyyy 年 MM 月 dd 日"
            value-format="timestamp"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="撤离原因" prop="reason">
          <el-input v-model="form.reason" type="textarea"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="removeFn">取 消</el-button>
        <el-button type="primary" @click="delFn">确 定</el-button>
      </div>
    </el-dialog>
    <el-dialog
      title="项目成员变更记录"
      :visible.sync="isMember"
      width="40%"
      center
      :before-close="closefn"
      :append-to-body="true"
      height="300"
    >
      <el-table :data="changeData" border style="width: 100%">
        <el-table-column prop="projectRole" align="center" label="项目角色">
          <template slot-scope="scope">
            {{ scope.row.projectRole | dict(dictData.projectRole) }}
          </template>
        </el-table-column>
        <el-table-column prop="memberName" align="center" label="员工名称"> </el-table-column>
        <el-table-column prop="projectMemberLogType" align="center" label="记录类型">
          <template slot-scope="scope">
            {{ scope.row.projectMemberLogType | dict(memberType) }}
          </template>
        </el-table-column>
        <el-table-column prop="changeDate" align="center" label="变更时间">
          <template slot-scope="scope">
            {{ scope.row.changeDate | dateFormat }}
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        style="margin-top: 10px"
        @size-change="handleSizeChange"
        @current-change="pageChangeHandler"
        :current-page="parameter.pageNow"
        :page-size="parameter.pageSize"
        prev-text="上一页"
        next-text="下一页"
        layout="->,total, prev, pager, next, slot, jumper"
        :total="parameter.total"
      >
        <span class="el-pagination__jump e_a_pagination">
          <el-input size="mini" v-model.number="pageSize" @blur="handlePageSize"></el-input>
          <span style="padding-top: 1px">条/页</span>
        </span>
      </el-pagination>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="closefn">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { mapState } from 'vuex'

export default {
  components: {
    ChoiceData: () => import('@/components/ChoiceData.vue'),
    Dictionary: () => import('@/components/Dictionary.vue'),
  },
  props: {
    options: {
      type: Object,
      default: function () {
        return {}
      },
    },
    deptData: {
      type: Array,
      defualt: function () {
        return []
      },
    },
    postData: {
      type: Array,
      defualt: function () {
        return []
      },
    },
  },
  data() {
    return {
      request: this.$api.user.listStaff,
      // deptData: [],
      // postData: [],
      dictData: {
        projectDept: [],
        projectRole: [],
      },
      joinDate: null, //T添加成员统一时间
      joinDateList: [],
      // joinDate: Date.now(), //T添加成员统一时间
      dialogFormVisible: false,
      form: {
        id: '',
        leaveDate: Date.now(),
        reason: '',
        name: null,
      },
      rules: {
        reason: [
          {
            required: true,
            message: '请输入撤离愿因',
            trigger: 'blur',
          },
          {
            min: 0,
            max: 500,
            message: '意见内容在0~500个字符',
            trigger: 'blur',
          },
        ],
      },
      k: null,
      i: null,
      isMember: false,
      changeData: [],
      memberType: [],
      parameter: {
        pageNow: 1,
        pageSize: 50,
        total: 0,
        deptId: null,
        projectId: null,
      },
      nowType: '',
      pageSize: 0,
    }
  },
  inject: ['refresh'],
  computed: {
    ...mapState({
      originalForm: state => state.project.originalForm,
      projectDeptBelongList: state => state.project.projectDeptBelongList,
      projectDeptMemberList: state => state.project.projectDeptMemberList,
      memberData: state => state.project.memberData,
      userInfo: state => state.user.userInfo,
    }),
  },
  watch: {
    projectDeptMemberList: {
      immediate: true, //初始化立即执行
      deep: true, //对象深度监测
      handler: function (newVal, oldVal) {
        var res = newVal.deepClone()
        var memberData = {}
        res.some(r => {
          let k = r.deptId

          if (!memberData[k]) {
            memberData[k] = []
          }

          memberData[k].push({
            projectMemberId: r.id,
            id: r.memberId,
            userName: r.memberName,
            phone: r.phone,
            postId: r.postId,
            projectRole: r.projectRole,
            joinDate: r.joinDate,
            joinResponsibility: r.joinResponsibility,
            status: r.status,
          })
        })

        this.$store.commit({
          type: 'project/SET_MEMBERDATA',
          memberData: memberData,
        })
      },
    },
  },
  created() {
    this.pageSize = this.userInfo.pageSizeLog
    this.parameter.pageSize = this.userInfo.pageSizeLog
    // this.$api.dict
    //   .listSysDictData("Project_Dept", true)
    //   .then((res) => {
    //     this.dictData.projectDept = res.data;
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //   });
    // this.$api.sysDept
    //   .listDept()
    //   .then((r) => {
    //     this.deptData = r.data;
    //   })
    //   .catch((err) => {});
    this.$api.dict
      .listSysDictData('Project_Role', true)
      .then(res => {
        this.dictData.projectRole = res.data
      })
      .catch(err => {
        console.log(err)
      })
    this.$api.dict
      .listSysDictData('PROJECT_DEPT_MEMBER_LOG_TYPE', true)
      .then(res => {
        this.memberType = res.data
      })
      .catch(err => {
        console.log(err)
      })

    // this.$api.sysConfig
    //   .listPost()
    //   .then((r) => {
    //     this.postData = r.data;
    //   })
    //   .catch((err) => {});
  },
  methods: {
    pageChangeHandler(val) {
      this.parameter.pageNow = val
      this.changeMember(this.nowType)
    },
    handleSizeChange(val) {
      this.parameter.pageSize = val
      this.parameter.pageNow = 1
      this.changeMember(this.nowType)
    },
    handlePageSize() {
      if (!this.pageSize) {
        this.pageSize = 1
      }
      let obj = { pageSizeLog: this.pageSize }
      this.loading = true
      this.$api.common
        .saveStaffPageSizeLog(obj)
        .then(res => {
          this.loading = false
          this.parameter.pageSize = this.pageSize
          this.userInfo.pageSizeLog = this.pageSize
          localStorage.setItem('userInfo', JSON.stringify(this.userInfo))
          this.changeMember(this.nowType)
        })
        .catch(err => {
          console.log(err)
          this.loading = false
        })
    },
    changeJoinDate(row) {
      this.joinDateList.push(row.deepClone())
    },
    async changeMember(type) {
      if (!this.nowType) {
      }
      this.nowType = type
      this.projectDeptBelongList.forEach(v => {
        if (v.type == type) {
          this.parameter.deptId = v.deptId
          this.parameter.projectId = v.projectId
        } else if (v.type == type) {
          this.parameter.deptId = v.deptId
          this.parameter.projectId = v.projectId
        }
      })
      try {
        const res = await this.$api.project.DeptMemberLogList(this.parameter)
        this.changeData = res.data.records
        this.parameter.total = res.data.total
        this.isMember = true
      } catch (error) {
        console.log(error)
      }
      // let obj = {
      //   deptId: "",
      //   projectId: "",
      // };
      // this.projectDeptBelongList.forEach((v) => {
      //   if (v.type == type) {
      //     obj.deptId = v.deptId;
      //     obj.projectId = v.projectId;
      //   } else if (v.type == type) {
      //     obj.deptId = v.deptId;
      //     obj.projectId = v.projectId;
      //   }
      // });
      // try {
      //   const res = await this.$api.project.DeptMemberLogList(obj);
      //   this.changeData = res.data.records;
      //   this.isMember = true;
      // } catch (error) {
      //   console.log(error);
      // }
    },
    closefn() {
      this.changeData = []
      this.parameter.pageNow = 1
      this.isMember = false
    },
    leavDateFn(value) {
      if (value == null) {
        value = new Date()
      } else {
        this.form.leaveDate = value
      }
    },
    updateDay() {
      this.joinDate = Date.now()
    },
    changeFn(value) {
      if (value == null) {
        value = new Date()
      }
      this.joinDate = value
    },
    removeFn() {
      this.dialogFormVisible = false
      this.form = {
        id: '',
        leaveDate: Date.now(),
        reason: '',
        name: '',
      }
      // this.$refs.formDialog.forEach(v => {
      //   v.resetFields()
      //   this.dialogFormVisible = false
      //   this.form = {
      //     id: '',
      //     leaveDate: Date.now(),
      //     reason: '',
      //     name: '',
      //   }
      // })
    },
    delFn() {
      this.$refs.formDialog.validate(valid => {
        if (valid) {
          if (this.form.id) {
            this.$api.project.delProjectleave(this.form)
            this.memberData[this.k].splice(this.i, 1)
          } else {
            this.memberData[this.k].splice(this.i, 1)
          }
          this.removeFn()
        }
      })
      // this.$refs.formDialog.forEach(v => {
      //   v.validate(valid => {
      //     if (valid) {
      //       if (this.form.id) {
      //         this.$api.project.delProjectleave(this.form)
      //         this.memberData[this.k].splice(this.i, 1)
      //       } else {
      //         this.memberData[this.k].splice(this.i, 1)
      //       }
      //       this.removeFn()
      //     }
      //   })
      // })
    },
    del(k, i, val) {
      if (val.projectMemberId) {
        this.dialogFormVisible = true
        this.k = k
        this.i = i
        this.projectDeptMemberList.forEach(async v => {
          if (v.memberName === val.userName) {
            this.form.id = v.id
            this.form.name = val.userName
          }
        })
      } else {
        this.memberData[k].splice(i, 1)
      }
    },
    // getProjectRole(id, deptId) { //原本根据项目经理所在部门ID赋值 "MEMBER"
    //   var str = "MEMBER";
    //   this.memberData[deptId].some((r) => {
    //     if (r.id == id) {
    //       str = r.projectRole;
    //     }
    //   });
    //   return str;
    // },
    choiceDataChange(r, deptId) {
      // r.some((r) => { //原本根据项目经理所在部门ID赋值 "MEMBER"
      //   r.projectRole = this.getProjectRole(r.id, deptId);
      // });
      r.some(r => {
        if (r.projectRole == 'MANAGER') {
          r.projectRole = 'MANAGER'
        } else {
          r.projectRole = 'MEMBER'
        }
      })
      r.filter(item => {
        return !this.projectDeptMemberList.find(prop => {
          return prop.memberName == item.userName
        })
      })
      r.forEach(v => {
        v.status = 1
        if (v.joinDate == null || v.joinDate == '') {
          v.joinDate = this.joinDate
        }
      })
      this.memberData[deptId] = r
      this.joinDateList.forEach(v => {
        this.memberData[deptId].forEach(k => {
          if (v.userName == k.userName) {
            k.joinDate = v.joinDate
          }
        })
      })
      this.$forceUpdate()
    },
    jurisdiction(res) {
      var b = true
      var deptIdList = this.$store.state.user.userInfo.deptIdList
      if (this.permission(['DEPTMEMBEREDIT'])) {
        if (
          this.options.operatingButton.some(r => {
            return r == 'EDIT'
          })
        ) {
          if (
            this.options.staffAttribute == 'LEADER' ||
            this.options.staffAttribute == 'MANAGER' ||
            this.options.staffAttribute == 'DEPT_MANAGER' ||
            this.options.staffAttribute == 'DEPTHEAD' ||
            this.options.staffAttribute == 'JH_MEMBER'
          ) {
            b = false
          }
        }
      }
      return b
    },
  },
  beforeDestroy() {},
}
</script>
<style scoped lang="scss">
@import '@/styles/config.scss';

.clearfloat {
  /deep/#member_search {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
}
.timestamp-title {
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
  span {
    font-weight: 700;
    font-size: 18px;
  }
}
</style>
